import { usePage } from '@inertiajs/vue3';
import { reactive } from 'vue';
import { Recruiter, Team } from '@/types/models';
import { Reactive } from '@vue/reactivity';

type GlobalProps = {
    isLocal: boolean;
    recruiter: Recruiter,
    team: Team,
}

export function useGlobalProps(): Reactive<GlobalProps> {
    return reactive<GlobalProps>(usePage().props as any);
}
